export const loading = () => {
    return {
        type: 'LOADING',
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const claimAppraiseErrors = (errors) => {
    return {
        type: 'CLAIM_APPRAISE_ERRORS',
        payload: errors,
        info: 'This action is used to '
    }
};
export const idChanged = (id) => {
    return {
        type: 'CLAIM_APPRAISE_ID_CHANGED',
        payload: id,
        info: 'This action is used to ...'
    }
};
export const firstnameChanged = (firstname) => {
    return {
        type: 'CLAIM_APPRAISE_FIRSTNAME_CHANGED',
        payload: firstname,
        info: 'This action is used to ...'
    }
};
export const lastnameChanged = (lastname) => {
    return {
        type: 'CLAIM_APPRAISE_LASTNAME_CHANGED',
        payload: lastname,
        info: 'This action is used to ...'
    }
};
export const addressChanged = (address) => {
    return {
        type: 'CLAIM_APPRAISE_ADDRESS_CHANGED',
        payload: address,
        info: 'This action is used to ...'
    }
};
export const phoneChanged = (phone) => {
    return {
        type: 'CLAIM_APPRAISE_PHONE_CHANGED',
        payload: phone,
        info: 'This action is used to ...'
    }
};
export const genderChanged = (gender) => {
    return {
        type: 'CLAIM_APPRAISE_GENDER_CHANGED',
        payload: gender,
        info: 'This action is used to ...'
    }
};
export const languageChanged = (language) => {
    return {
        type: 'CLAIM_APPRAISE_LANGUAGE_CHANGED',
        payload: language,
        info: 'This action is used to ...'
    }
};
export const dossierimfChanged = (dossierimf) => {
    return {
        type: 'CLAIM_APPRAISE_DOSSIERIMF_CHANGED',
        payload: dossierimf,
        info: 'This action is used to ...'
    }
};
export const codeChanged = (code) => {
    return {
        type: 'CLAIM_APPRAISE_CODE_CHANGED',
        payload: code,
        info: 'This action is used to ...'
    }
};
export const recordedAtChanged = (recordedAt) => {
    return {
        type: 'CLAIM_APPRAISE_RECORDED_AT_CHANGED',
        payload: recordedAt,
        info: 'This action is used to ...'
    }
};
export const collectChanged = (collect) => {
    return {
        type: 'CLAIM_APPRAISE_COLLECT_CHANGED',
        payload: collect,
        info: 'This action is used to ...'
    }
};
export const subjectChanged = (subject) => {
    return {
        type: 'CLAIM_APPRAISE_SUBJECT_CHANGED',
        payload: subject,
        info: 'This action is used to ...'
    }
};
export const underSubjectChanged = (underSubject) => {
    return {
        type: 'CLAIM_APPRAISE_UNDERSUBJECT_CHANGED',
        payload: underSubject,
        info: 'This action is used to ...'
    }
};
export const productChanged = (product) => {
    return {
        type: 'CLAIM_APPRAISE_PRODUCT_CHANGED',
        payload: product,
        info: 'This action is used to ...'
    }
};
export const unitChanged = (unit) => {
    return {
        type: 'CLAIM_APPRAISE_UNIT_CHANGED',
        payload: unit,
        info: 'This action is used to ...'
    }
};
export const contentChanged = (content) => {
    return {
        type: 'CLAIM_APPRAISE_CONTENT_CHANGED',
        payload: content,
        info: 'This action is used to ...'
    }
};
export const reset = () => {
    return {
        type: 'CLAI_APPRAISE_RESET',
        info: 'This action is used to ...'
    }
};
export const itemsChanged = (items) =>{
    return {
        type: 'CLAIM_APPRAISE_ITEMS_CHANGED',
        payload: items,
        info: 'This action is used to ...'
    }
};
export const agentsChanged = (agents) =>{
    return {
        type: 'CLAIM_APPRAISE_AGENTS_CHANGED',
        payload: agents,
        info: 'This action is used to ...'
    }
};
export const selectedItemChanged = (selectedItem) =>{
    return {
        type: 'CLAIM_APPRAISE_SELECTED_ITEM_CHANGED',
        payload: selectedItem,
        info: 'This action is used to ...'
    }
};
export const solutionChanged = (solution) =>{
    return {
        type: 'CLAIM_APPRAISE_SOLUTION_CHANGED',
        payload: solution,
        info: 'This action is used to ...'
    }
};
export const solutionIdChanged = (solutionId) =>{
    return {
        type: 'CLAIM_APPRAISE_SOLUTION_ID_CHANGED',
        payload: solutionId,
        info: 'This action is used to ...'
    }
};
export const commentChanged = (comment) =>{
    return {
        type: 'CLAIM_APPRAISE_COMMENT_CHANGED',
        payload: comment,
        info: 'This action is used to ...'
    }
};
export const commentaChanged = (commenta) =>{
    return {
        type: 'CLAIM_APPRAISE_COMMENTA_CHANGED',
        payload: commenta,
        info: 'This action is used to ...'
    }
};
export const statusChanged = (status) =>{
    return {
        type: 'CLAIM_APPRAISE_STATUS_CHANGED',
        payload: status,
        info: 'This action is used to ...'
    }
};
export const motifChanged = (motif) =>{
    return {
        type: 'CLAIM_APPRAISE_MOTIF_CHANGED',
        payload: motif,
        info: 'This action is used to ...'
    }
};
export const createdAtChanged = (createdAt) => {
    return {
        type: 'CLAIM_APPRAISE_CREATED_AT_CHANGED',
        payload: createdAt,
        info: 'This action is used to ...'
    }
};
export const createdByChanged = (createdBy) => {
    return {
        type: 'CLAIM_APPRAISE_CREATED_BY_CHANGED',
        payload: createdBy,
        info: 'This action is used to ...'
    }
};
export const assignedAtChanged = (assignedAt) => {
    return {
        type: 'CLAIM_APPRAISE_ASSIGNED_AT_CHANGED',
        payload: assignedAt,
        info: 'This action is used to ...'
    }
};
export const handledAtChanged = (handledAt) => {
    return {
        type: 'CLAIM_APPRAISE_HANDLED_AT_CHANGED',
        payload: handledAt,
        info: 'This action is used to ...'
    }
};
export const handledByChanged = (handledBy) => {
    return {
        type: 'CLAIM_APPRAISE_HANDLED_BY_CHANGED',
        payload: handledBy,
        info: 'This action is used to ...'
    }
};
export const appraisedAtChanged = (appraisedAt) => {
    return {
        type: 'CLAIM_APPRAISE_APPRAISED_AT_CHANGED',
        payload: appraisedAt,
        info: 'This action is used to ...'
    }
};
export const appraisedByChanged = (appraisedBy) => {
    return {
        type: 'CLAIM_APPRAISE_APPRAISED_BY_CHANGED',
        payload: appraisedBy,
        info: 'This action is used to ...'
    }
};
export const appraisalChanged = (appraisal) => {
    return {
        type: 'CLAIM_APPRAISE_APPRAISAL_CHANGED',
        payload: appraisal,
        info: 'This action is used to ...'
    }
};
export const selectedFilesReset = (selectedFiles) =>{
    return {
        type: 'CLAIM_APPRAISE_SELECTED_FILES_RESET',
        payload: selectedFiles,
        info: 'This action is used to ...'
    }
};
export const selectedItemFilesChanged = (selectedItemFiles) =>{
    return {
        type: 'CLAIM_APPRAISE_SELECTED_ITEM_FILES_CHANGED',
        payload: selectedItemFiles,
        info: 'This action is used to ...'
    }
};
export const selectedItemAudioChanged = (selectedItemAudio) =>{
    return {
        type: 'CLAIM_APPRAISE_SELECTED_ITEM_AUDIO_CHANGED',
        payload: selectedItemAudio,
        info: 'This action is used to ...'
    }
};
export const authorizeChanged = (item) =>{
    return {
        type: 'CLAIM_APPRAISE_AUTHORIZE',
        payload: item,
        info: 'This action is used to ...'
    }
};
export const etatChanged = (etat) =>{
    return {
        type: 'CLAIM_APPRAISE_ETAT_CHANGED',
        payload: etat,
        info: 'This action is used to ...'
    }
};
export const etat2Changed = (etat2) =>{
    return {
        type: 'CLAIM_APPRAISE_ETAT2_CHANGED',
        payload: etat2,
        info: 'This action is used to ...'
    }
};