export const loading = () => {
    return {
        type: 'LOADING',
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const suggestionHandleErrors = (errors) => {
    return {
        type: 'SUGGESTION_HANDLE_ERRORS',
        payload: errors,
        info: 'This action is used to '
    }
};
export const idChanged = (id) => {
    return {
        type: 'SUGGESTION_HANDLE_ID_CHANGED',
        payload: id,
        info: 'This action is used to ...'
    }
};
export const firstnameChanged = (firstname) => {
    return {
        type: 'SUGGESTION_HANDLE_FIRSTNAME_CHANGED',
        payload: firstname,
        info: 'This action is used to ...'
    }
};
export const lastnameChanged = (lastname) => {
    return {
        type: 'SUGGESTION_HANDLE_LASTNAME_CHANGED',
        payload: lastname,
        info: 'This action is used to ...'
    }
};
export const addressChanged = (address) => {
    return {
        type: 'SUGGESTION_HANDLE_ADDRESS_CHANGED',
        payload: address,
        info: 'This action is used to ...'
    }
};
export const phoneChanged = (phone) => {
    return {
        type: 'SUGGESTION_HANDLE_PHONE_CHANGED',
        payload: phone,
        info: 'This action is used to ...'
    }
};
export const genderChanged = (gender) => {
    return {
        type: 'SUGGESTION_HANDLE_GENDER_CHANGED',
        payload: gender,
        info: 'This action is used to ...'
    }
};
export const languageChanged = (language) => {
    return {
        type: 'SUGGESTION_HANDLE_LANGUAGE_CHANGED',
        payload: language,
        info: 'This action is used to ...'
    }
};
export const dossierimfChanged = (dossierimf) => {
    return {
        type: 'SUGGESTION_HANDLE_DOSSIERIMF_CHANGED',
        payload: dossierimf,
        info: 'This action is used to ...'
    }
};
export const codeChanged = (code) => {
    return {
        type: 'SUGGESTION_HANDLE_CODE_CHANGED',
        payload: code,
        info: 'This action is used to ...'
    }
};
export const recordedAtChanged = (recordedAt) => {
    return {
        type: 'SUGGESTION_HANDLE_RECORDED_AT_CHANGED',
        payload: recordedAt,
        info: 'This action is used to ...'
    }
};
export const collectChanged = (collect) => {
    return {
        type: 'SUGGESTION_HANDLE_COLLECT_CHANGED',
        payload: collect,
        info: 'This action is used to ...'
    }
};
export const subjectChanged = (subject) => {
    return {
        type: 'SUGGESTION_HANDLE_SUBJECT_CHANGED',
        payload: subject,
        info: 'This action is used to ...'
    }
};
export const productChanged = (product) => {
    return {
        type: 'SUGGESTION_HANDLE_PRODUCT_CHANGED',
        payload: product,
        info: 'This action is used to ...'
    }
};
export const unitChanged = (unit) => {
    return {
        type: 'SUGGESTION_HANDLE_UNIT_CHANGED',
        payload: unit,
        info: 'This action is used to ...'
    }
};
export const contentChanged = (content) => {
    return {
        type: 'SUGGESTION_HANDLE_CONTENT_CHANGED',
        payload: content,
        info: 'This action is used to ...'
    }
};
export const reset = () => {
    return {
        type: 'SUGGESTION_HANDLE_RESET',
        info: 'This action is used to ...'
    }
};
export const itemsChanged = (items) =>{
    return {
        type: 'SUGGESTION_HANDLE_ITEMS_CHANGED',
        payload: items,
        info: 'This action is used to ...'
    }
};
export const selectedItemChanged = (selectedItem) =>{
    return {
        type: 'SUGGESTION_HANDLE_SELECTED_ITEM_CHANGED',
        payload: selectedItem,
        info: 'This action is used to ...'
    }
};
export const solutionChanged = (solution) =>{
    return {
        type: 'SUGGESTION_HANDLE_SOLUTION_CHANGED',
        payload: solution,
        info: 'This action is used to ...'
    }
};
export const statusChanged = (status) =>{
    return {
        type: 'SUGGESTION_HANDLE_STATUS_CHANGED',
        payload: status,
        info: 'This action is used to ...'
    }
};
export const createdAtChanged = (createdAt) => {
    return {
        type: 'SUGGESTION_HANDLE_CREATED_AT_CHANGED',
        payload: createdAt,
        info: 'This action is used to ...'
    }
};
export const createdByChanged = (createdBy) => {
    return {
        type: 'SUGGESTION_HANDLE_CREATED_BY_CHANGED',
        payload: createdBy,
        info: 'This action is used to ...'
    }
};
export const handledAtChanged = (handledAt) => {
    return {
        type: 'SUGGESTION_HANDLE_HANDLED_AT_CHANGED',
        payload: handledAt,
        info: 'This action is used to ...'
    }
};
export const handledByChanged = (handledBy) => {
    return {
        type: 'SUGGESTION_HANDLE_HANDLED_BY_CHANGED',
        payload: handledBy,
        info: 'This action is used to ...'
    }
};
export const resolvedAtChanged = (resolvedAt) => {
    return {
        type: 'SUGGESTION_HANDLE_RESOLVED_AT_CHANGED',
        payload: resolvedAt,
        info: 'This action is used to ...'
    }
};
export const resolvedByChanged = (resolvedBy) => {
    return {
        type: 'SUGGESTION_HANDLE_RESOLVED_BY_CHANGED',
        payload: resolvedBy,
        info: 'This action is used to ...'
    }
};
export const selectedFilesReset = (selectedFiles) =>{
    return {
        type: 'SUGGESTION_HANDLE_SELECTED_FILES_RESET',
        payload: selectedFiles,
        info: 'This action is used to ...'
    }
};
export const selectedItemFilesChanged = (selectedItemFiles) =>{
    return {
        type: 'SUGGESTION_HANDLE_SELECTED_ITEM_FILES_CHANGED',
        payload: selectedItemFiles,
        info: 'This action is used to ...'
    }
};
export const showSelectPrintItemChanged = (show) =>{
    return{
        type:'SUGGESTION_HANDLE_SHOW_SELECT_PRINT_ITEMS',
     
        info:'This action is used to select item where users would export'
    }
}
export const crewChanged = (crew) => {
    return {
        type: 'SUGGESTION_HANDLE_CREW_CHANGED',
        payload:crew,
        info: 'This action is used to ...'
    }
};
export const commentChanged = (comment) => {
    return {
        type: 'SUGGESTION_HANDLE_COMMENT_CHANGED',
        payload:comment,
        info: 'This action is used to ...'
    }
};
export const etatChanged = (etat) => {
    return {
        type: 'SUGGESTION_HANDLE_CREW_CHANGED',
        payload:etat,
        info: 'This action is used to ...'
    }
};
export const etat2Changed = (etat2) => {
    return {
        type: 'SUGGESTION_HANDLE_CREW_CHANGED',
        payload:etat2,
        info: 'This action is used to ...'
    }
};