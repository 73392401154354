export const loading = () => {
    return {
        type: 'LOADING',
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const claimAssuranceErrors = (errors) => {
    return {
        type: 'CLAIM_ASSURANCE_ERRORS',
        payload: errors,
        info: 'This action is used to '
    }
};
export const idChanged = (id) => {
    return {
        type: 'CLAIM_ASSURANCE_ID_CHANGED',
        payload: id,
        info: 'This action is used to ...'
    }
};
export const firstnameChanged = (firstname) => {
    return {
        type: 'CLAIM_ASSURANCE_FIRSTNAME_CHANGED',
        payload: firstname,
        info: 'This action is used to ...'
    }
};
export const lastnameChanged = (lastname) => {
    return {
        type: 'CLAIM_ASSURANCE_LASTNAME_CHANGED',
        payload: lastname,
        info: 'This action is used to ...'
    }
};
export const addressChanged = (address) => {
    return {
        type: 'CLAIM_ASSURANCE_ADDRESS_CHANGED',
        payload: address,
        info: 'This action is used to ...'
    }
};
export const phoneChanged = (phone) => {
    return {
        type: 'CLAIM_ASSURANCE_PHONE_CHANGED',
        payload: phone,
        info: 'This action is used to ...'
    }
};
export const genderChanged = (gender) => {
    return {
        type: 'CLAIM_ASSURANCE_GENDER_CHANGED',
        payload: gender,
        info: 'This action is used to ...'
    }
};
export const languageChanged = (language) => {
    return {
        type: 'CLAIM_ASSURANCE_LANGUAGE_CHANGED',
        payload: language,
        info: 'This action is used to ...'
    }
};
export const dossierimfChanged = (dossierimf) => {
    return {
        type: 'CLAIM_ASSURANCE_DOSSIERIMF_CHANGED',
        payload: dossierimf,
        info: 'This action is used to ...'
    }
};
export const codeChanged = (code) => {
    return {
        type: 'CLAIM_ASSURANCE_CODE_CHANGED',
        payload: code,
        info: 'This action is used to ...'
    }
};
export const recordedAtChanged = (recordedAt) => {
    return {
        type: 'CLAIM_ASSURANCE_RECORDED_AT_CHANGED',
        payload: recordedAt,
        info: 'This action is used to ...'
    }
};
export const collectChanged = (collect) => {
    return {
        type: 'CLAIM_ASSURANCE_COLLECT_CHANGED',
        payload: collect,
        info: 'This action is used to ...'
    }
};
export const subjectChanged = (subject) => {
    return {
        type: 'CLAIM_ASSURANCE_SUBJECT_CHANGED',
        payload: subject,
        info: 'This action is used to ...'
    }
};
export const underSubjectChanged = (underSubject) => {
    return {
        type: 'CLAIM_ASSURANCE_UNDERSUBJECT_CHANGED',
        payload: underSubject,
        info: 'This action is used to ...'
    }
};
export const productChanged = (product) => {
    return {
        type: 'CLAIM_ASSURANCE_PRODUCT_CHANGED',
        payload: product,
        info: 'This action is used to ...'
    }
};
export const unitChanged = (unit) => {
    return {
        type: 'CLAIM_ASSURANCE_UNIT_CHANGED',
        payload: unit,
        info: 'This action is used to ...'
    }
};
export const contentChanged = (content) => {
    return {
        type: 'CLAIM_ASSURANCE_CONTENT_CHANGED',
        payload: content,
        info: 'This action is used to ...'
    }
};
export const reset = () => {
    return {
        type: 'CLAI_ASSURANCE_RESET',
        info: 'This action is used to ...'
    }
};
export const itemsChanged = (items) =>{
    return {
        type: 'CLAIM_ASSURANCE_ITEMS_CHANGED',
        payload: items,
        info: 'This action is used to ...'
    }
};
export const agentsChanged = (agents) =>{
    return {
        type: 'CLAIM_ASSURANCE_AGENTS_CHANGED',
        payload: agents,
        info: 'This action is used to ...'
    }
};
export const selectedItemChanged = (selectedItem) =>{
    return {
        type: 'CLAIM_ASSURANCE_SELECTED_ITEM_CHANGED',
        payload: selectedItem,
        info: 'This action is used to ...'
    }
};
export const solutionChanged = (solution) =>{
    return {
        type: 'CLAIM_ASSURANCE_SOLUTION_CHANGED',
        payload: solution,
        info: 'This action is used to ...'
    }
};
export const commentChanged = (comment) =>{
    return {
        type: 'CLAIM_ASSURANCE_COMMENT_CHANGED',
        payload: comment,
        info: 'This action is used to ...'
    }
};
export const newSolutionChanged = (new_solution) =>{
    return {
        type: 'CLAIM_ASSURANCE_NEW_SOLUTION_CHANGED',
        payload: new_solution,
        info: 'This action is used to ...'
    }
};
export const newCommentChanged = (new_comment) =>{
    return {
        type: 'CLAIM_ASSURANCE_NEW_COMMENT_CHANGED',
        payload: new_comment,
        info: 'This action is used to ...'
    }
};
export const statusChanged = (status) =>{
    return {
        type: 'CLAIM_ASSURANCE_STATUS_CHANGED',
        payload: status,
        info: 'This action is used to ...'
    }
};
export const motifChanged = (motif) =>{
    return {
        type: 'CLAIM_ASSURANCE_MOTIF_CHANGED',
        payload: motif,
        info: 'This action is used to ...'
    }
};
export const createdAtChanged = (createdAt) => {
    return {
        type: 'CLAIM_ASSURANCE_CREATED_AT_CHANGED',
        payload: createdAt,
        info: 'This action is used to ...'
    }
};
export const createdByChanged = (createdBy) => {
    return {
        type: 'CLAIM_ASSURANCE_CREATED_BY_CHANGED',
        payload: createdBy,
        info: 'This action is used to ...'
    }
};
export const assignedAtChanged = (assignedAt) => {
    return {
        type: 'CLAIM_ASSURANCE_ASSIGNED_AT_CHANGED',
        payload: assignedAt,
        info: 'This action is used to ...'
    }
};
export const handledAtChanged = (handledAt) => {
    return {
        type: 'CLAIM_ASSURANCE_HANDLED_AT_CHANGED',
        payload: handledAt,
        info: 'This action is used to ...'
    }
};
export const handledByChanged = (handledBy) => {
    return {
        type: 'CLAIM_ASSURANCE_HANDLED_BY_CHANGED',
        payload: handledBy,
        info: 'This action is used to ...'
    }
};
export const appraisedAtChanged = (appraisedAt) => {
    return {
        type: 'CLAIM_ASSURANCE_APPRAISED_AT_CHANGED',
        payload: appraisedAt,
        info: 'This action is used to ...'
    }
};
export const appraisedByChanged = (appraisedBy) => {
    return {
        type: 'CLAIM_ASSURANCE_APPRAISED_BY_CHANGED',
        payload: appraisedBy,
        info: 'This action is used to ...'
    }
};
export const appraisalChanged = (appraisal) => {
    return {
        type: 'CLAIM_ASSURANCE_APPRAISAL_CHANGED',
        payload: appraisal,
        info: 'This action is used to ...'
    }
};
export const selectedFilesReset = (selectedFiles) =>{
    return {
        type: 'CLAIM_ASSURANCE_SELECTED_FILES_RESET',
        payload: selectedFiles,
        info: 'This action is used to ...'
    }
};
export const selectedItemFilesChanged = (selectedItemFiles) =>{
    return {
        type: 'CLAIM_ASSURANCE_SELECTED_ITEM_FILES_CHANGED',
        payload: selectedItemFiles,
        info: 'This action is used to ...'
    }
};
export const selectedItemAudioChanged = (selectedItemAudio) =>{
    return {
        type: 'CLAIM_ASSURANCE_SELECTED_ITEM_AUDIO_CHANGED',
        payload: selectedItemAudio,
        info: 'This action is used to ...'
    }
};
export const authorizeChanged = (item) =>{
    return {
        type: 'CLAIM_ASSURANCE_AUTHORIZE',
        payload: item,
        info: 'This action is used to ...'
    }
};
export const externalRemediesChanged = (externalRemedies) =>{
    return {
        type: 'CLAIM_LIST_EXTERNAL_REMEDIES_CHANGED',
        payload: externalRemedies,
        info: 'This action is used to ...'
    }
};
export const etatChanged = (etat) =>{
    return {
        type: 'CLAIM_ASSURANCE_ETAT_CHANGED',
        payload: etat,
        info: 'This action is used to ...'
    }
};
export const etat2Changed = (etat2) =>{
    return {
        type: 'CLAIM_ASSURANCE_ETAT2_CHANGED',
        payload: etat2,
        info: 'This action is used to ...'
    }
};
export const etat3Changed = (etat3) =>{
    return {
        type: 'CLAIM_ASSURANCE_ETAT3_CHANGED',
        payload: etat3,
        info: 'This action is used to ...'
    }
};