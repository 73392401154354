export const loading = () =>{
    return {
        type: 'LOADING',
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const reportErrorsChanged = (errors) =>{
    return {
        type: 'REPORT_ERRORS',
        payload: errors,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const yearChanged = (year) =>{
    return {
        type: 'REPORT_YEAR',
        payload: year,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const startChanged = (start) =>{
    return {
        type: 'REPORT_START',
        payload: start,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const endChanged = (end) =>{
    return {
        type: 'REPORT_END',
        payload: end,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const startDPChanged = (start) =>{
    return {
        type: 'REPORT_START_DP',
        payload: start,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const endDPChanged = (end) =>{
    return {
        type: 'REPORT_END_DP',
        payload: end,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const unitChanged = (unit) =>{
    return {
        type: 'REPORT_UNIT',
        payload: unit,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const posChanged = (pos) =>{
    return {
        type: 'REPORT_POS',
        payload: pos,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const piloteChanged = (pilote) =>{
    return {
        type: 'REPORT_PILOTE',
        payload: pilote,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const directorChanged = (director) =>{
    return {
        type: 'REPORT_DIRECTOR',
        payload: director,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const monthsYearsChanged = (monthsYears) =>{
    return {
        type: 'REPORT_MONTHS_YEARS',
        payload: monthsYears,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const claimsChanged = (claims) =>{
    return {
        type: 'REPORT_CLAIMS',
        payload: claims,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const denunciationsChanged = (denunciations) =>{
    return {
        type: 'REPORT_DENUNCIATIONS',
        payload: denunciations,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const suggestionsChanged = (suggestions) =>{
    return {
        type: 'REPORT_SUGGESTIONS',
        payload: suggestions,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const claimTrendChanged = (claimTrend) =>{
    return {
        type: 'REPORT_CLAIM_TREND',
        payload: claimTrend,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const globalTrendChanged = (globalTrend) =>{
    return {
        type: 'REPORT_GLOBAL_TREND',
        payload: globalTrend,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const genreTrendChanged = (genreTrend) =>{
    return {
        type: 'REPORT_GENRE_TREND',
        payload: genreTrend,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const statChanged = (stat) =>{
    return {
        type: 'REPORT_STAT',
        payload: stat,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const responseRateChanged = (responseRate) =>{
    return {
        type: 'REPORT_RESPONSE_RATE',
        payload: responseRate,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const satisfactionRateChanged = (satisfactionRate) =>{
    return {
        type: 'REPORT_SATISFACTION_RATE',
        payload: satisfactionRate,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const claimReportChanged = (claimReport) =>{
    return {
        type: 'REPORT_CLAIM',
        payload: claimReport,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const denunReportChanged = (denunReport) =>{
    return {
        type: 'REPORT_DENUN',
        payload: denunReport,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const sugReportChanged = (sugReport) =>{
    return {
        type: 'REPORT_SUG',
        payload: sugReport,
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const basicStatChanged = (basicStat) =>{
    return {
        type: 'REPORT_BASIC_STAT',
        payload: basicStat,
        info: 'This action is used to wait while loading data into the platform'
    }
};