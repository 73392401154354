// export const ColourOption {
//      value: string;
//      label: string;
//     color: string;
//      isFixed?: boolean;
//      isDisabled?: boolean;
// }
  
export const colourOptions = [
    { value: 'H1', label: 'H1', color: '#00B8D9' },
    { value: 'H2', label: 'H2', color: '#0052CC' },
    { value: 'H3', label: 'H3', color: '#5243AA' },
    { value: 'H4', label: 'H4', color: '#FF5630'},
    { value: 'H5', label: 'H5', color: '#FF8B00' },
    { value: 'H6', label: 'H6', color: '#FFC400' },
    { value: 'H7', label: 'H7', color: '#36B37E' },
    { value: 'H8', label: 'H8', color: '#00875A' },
    { value: 'H9', label: 'H9', color: '#253858' },
    { value: 'H10', label: 'H10', color: '#666666' },
    { value: 'H11', label: 'H11', color: '#3333ff' },
    { value: 'H12', label: 'H12', color: '#99003d' },
    { value: 'H13', label: 'H13', color: '#00cc00' },
    { value: 'H14', label: 'H14', color: '#333300' },
];