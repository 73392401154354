export const loading = () => {
    return {
        type: 'LOADING',
        info: 'This action is used to wait while loading data into the platform'
    }
};
export const claimHandleErrors = (errors) => {
    return {
        type: 'CLAIM_HANDLE_ERRORS',
        payload: errors,
        info: 'This action is used to '
    }
};
export const idChanged = (id) => {
    return {
        type: 'CLAIM_HANDLE_ID_CHANGED',
        payload: id,
        info: 'This action is used to ...'
    }
};
export const firstnameChanged = (firstname) => {
    return {
        type: 'CLAIM_HANDLE_FIRSTNAME_CHANGED',
        payload: firstname,
        info: 'This action is used to ...'
    }
};
export const lastnameChanged = (lastname) => {
    return {
        type: 'CLAIM_HANDLE_LASTNAME_CHANGED',
        payload: lastname,
        info: 'This action is used to ...'
    }
};
export const addressChanged = (address) => {
    return {
        type: 'CLAIM_HANDLE_ADDRESS_CHANGED',
        payload: address,
        info: 'This action is used to ...'
    }
};
export const phoneChanged = (phone) => {
    return {
        type: 'CLAIM_HANDLE_PHONE_CHANGED',
        payload: phone,
        info: 'This action is used to ...'
    }
};
export const genderChanged = (gender) => {
    return {
        type: 'CLAIM_HANDLE_GENDER_CHANGED',
        payload: gender,
        info: 'This action is used to ...'
    }
};
export const languageChanged = (language) => {
    return {
        type: 'CLAIM_HANDLE_LANGUAGE_CHANGED',
        payload: language,
        info: 'This action is used to ...'
    }
};
export const dossierimfChanged = (dossierimf) => {
    return {
        type: 'CLAIM_HANDLE_DOSSIERIMF_CHANGED',
        payload: dossierimf,
        info: 'This action is used to ...'
    }
};
export const codeChanged = (code) => {
    return {
        type: 'CLAIM_HANDLE_CODE_CHANGED',
        payload: code,
        info: 'This action is used to ...'
    }
};
export const recordedAtChanged = (recordedAt) => {
    return {
        type: 'CLAIM_HANDLE_RECORDED_AT_CHANGED',
        payload: recordedAt,
        info: 'This action is used to ...'
    }
};
export const collectChanged = (collect) => {
    return {
        type: 'CLAIM_HANDLE_COLLECT_CHANGED',
        payload: collect,
        info: 'This action is used to ...'
    }
};
export const subjectChanged = (subject) => {
    return {
        type: 'CLAIM_HANDLE_SUBJECT_CHANGED',
        payload: subject,
        info: 'This action is used to ...'
    }
};
export const underSubjectChanged = (underSubject) => {
    return {
        type: 'CLAIM_HANDLE_UNDERSUBJECT_CHANGED',
        payload: underSubject,
        info: 'This action is used to ...'
    }
};
export const productChanged = (product) => {
    return {
        type: 'CLAIM_HANDLE_PRODUCT_CHANGED',
        payload: product,
        info: 'This action is used to ...'
    }
};
export const unitChanged = (unit) => {
    return {
        type: 'CLAIM_HANDLE_UNIT_CHANGED',
        payload: unit,
        info: 'This action is used to ...'
    }
};
export const contentChanged = (content) => {
    return {
        type: 'CLAIM_HANDLE_CONTENT_CHANGED',
        payload: content,
        info: 'This action is used to ...'
    }
};
export const reset = () => {
    return {
        type: 'CLAI_HANDLE_RESET',
        info: 'This action is used to ...'
    }
};
export const itemsChanged = (items) =>{
    return {
        type: 'CLAIM_HANDLE_ITEMS_CHANGED',
        payload: items,
        info: 'This action is used to ...'
    }
};
export const agentsChanged = (agents) =>{
    return {
        type: 'CLAIM_HANDLE_AGENTS_CHANGED',
        payload: agents,
        info: 'This action is used to ...'
    }
};
export const selectedItemChanged = (selectedItem) =>{
    return {
        type: 'CLAIM_HANDLE_SELECTED_ITEM_CHANGED',
        payload: selectedItem,
        info: 'This action is used to ...'
    }
};
export const solutionChanged = (solution) =>{
    return {
        type: 'CLAIM_HANDLE_SOLUTION_CHANGED',
        payload: solution,
        info: 'This action is used to ...'
    }
};
export const solutionIdChanged = (solutionId) =>{
    return {
        type: 'CLAIM_HANDLE_SOLUTION_ID_CHANGED',
        payload: solutionId,
        info: 'This action is used to ...'
    }
};
export const commentChanged = (comment) =>{
    return {
        type: 'CLAIM_HANDLE_COMMENT_CHANGED',
        payload: comment,
        info: 'This action is used to ...'
    }
};
export const statusChanged = (status) =>{
    return {
        type: 'CLAIM_HANDLE_STATUS_CHANGED',
        payload: status,
        info: 'This action is used to ...'
    }
};
export const motifChanged = (motif) =>{
    return {
        type: 'CLAIM_HANDLE_MOTIF_CHANGED',
        payload: motif,
        info: 'This action is used to ...'
    }
};
export const createdAtChanged = (createdAt) => {
    return {
        type: 'CLAIM_HANDLE_CREATED_AT_CHANGED',
        payload: createdAt,
        info: 'This action is used to ...'
    }
};

export const createdByChanged = (createdBy) => {
    return {
        type: 'CLAIM_HANDLE_CREATED_BY_CHANGED',
        payload: createdBy,
        info: 'This action is used to ...'
    }
};
export const assignedByChanged = (assignedBy) => {
    return {
        type: 'CLAIM_HANDLE_ASSIGNED_BY_CHANGED',
        payload: assignedBy,
        info: 'This action is used to ...'
    }
};
export const assignedAtChanged = (assignedAt) => {
    return {
        type: 'CLAIM_HANDLE_ASSIGNED_AT_CHANGED',
        payload: assignedAt,
        info: 'This action is used to ...'
    }
};
export const handledAtChanged = (handledAt) => {
    return {
        type: 'CLAIM_HANDLE_HANDLED_AT_CHANGED',
        payload: handledAt,
        info: 'This action is used to ...'
    }
};
export const handledByChanged = (handledBy) => {
    return {
        type: 'CLAIM_HANDLE_HANDLED_BY_CHANGED',
        payload: handledBy,
        info: 'This action is used to ...'
    }
};
export const selectedFilesReset = (selectedFiles) =>{
    return {
        type: 'CLAIM_HANDLE_SELECTED_FILES_RESET',
        payload: selectedFiles,
        info: 'This action is used to ...'
    }
};
export const selectedItemFilesChanged = (selectedItemFiles) =>{
    return {
        type: 'CLAIM_HANDLE_SELECTED_ITEM_FILES_CHANGED',
        payload: selectedItemFiles,
        info: 'This action is used to ...'
    }
};

export const selectedItemAudioChanged = (selectedItemAudio) =>{
    return {
        type: 'CLAIM_HANDLE_SELECTED_ITEM_AUDIO_CHANGED',
        payload: selectedItemAudio,
        info: 'This action is used to ...'
    }
};

export const authorizeChanged = (info) =>{
    return {
        type: 'CLAIM_HANDLE_AUTHORIZE',
        payload: info,
        info: 'This action is used to ...'
    }
};

export const crewChanged = (crew) => {
    return {
        type: 'CLAIM_HANDLE_CREW_CHANGED',
        payload:crew,
        info: 'This action is used to ...'
    }
};
export const objetLevelChanged = (objetLevel) => {
    return {
        type: 'CLAIM_HANDLE_OBJET_LEVEL_CHANGED',
        payload:objetLevel,
        info: 'This action is used to ...'
    }
};
export const newSolutionChanged = (new_solution) =>{
    return {
        type: 'CLAIM_HANDLE_NEW_SOLUTION_CHANGED',
        payload: new_solution,
        info: 'This action is used to ...'
    }
};
export const newCommentChanged = (new_comment) =>{
    return {
        type: 'CLAIM_HANDLE_NEW_COMMENT_CHANGED',
        payload: new_comment,
        info: 'This action is used to ...'
    }
};
export const etatChanged = (etat) =>{
    return {
        type: 'CLAIM_HANDLE_ETAT_CHANGED',
        payload: etat,
        info: 'This action is used to ...'
    }
};
export const etat2Changed = (etat2) =>{
    return {
        type: 'CLAIM_HANDLE_ETAT2_CHANGED',
        payload: etat2,
        info: 'This action is used to ...'
    }
};
export const etat3Changed = (etat3) =>{
    return {
        type: 'CLAIM_HANDLE_ETAT3_CHANGED',
        payload: etat3,
        info: 'This action is used to ...'
    }
};
export const etat4Changed = (etat4) =>{
    return {
        type: 'CLAIM_HANDLE_ETAT4_CHANGED',
        payload: etat4,
        info: 'This action is used to ...'
    }
};
export const anonymatChanged = (anonymat) =>{
    return {
        type: 'CLAIM_HANDLE_ANONYMAT_CHANGED',
        payload: anonymat,
        info: 'This action is used to ...'
    }
};
export const transmittedChanged = (transmitted) =>{
    return {
        type: 'CLAIM_HANDLE_TRANSMITTED_CHANGED',
        payload: transmitted,
        info: 'This action is used to ...'
    }
};
export const sessionChanged = (session) =>{
    return {
        type: 'CLAIM_HANDLE_SESSION_CHANGED',
        payload: session,
        info: 'This action is used to ...'
    }
};
export const solutionExistantChanged = (solutionExistant) =>{
    return {
        type: 'CLAIM_HANDLE_SOLUTION_EXISTANT_CHANGED',
        payload: solutionExistant,
        info: 'This action is used to ...'
    }
};

